<template>
  <div>
    <div class="title">
      <div>{{ userInfo.orgName }}</div>
      <a href="javasctipt:;" @click="backLevel" class="backBtn">返回</a>
      <a class="homeBtn" @click="backHome" href="javascript:;">首页</a>
      <p>欢迎您！{{ $store.state.globalInfo.nickname }}</p>
    </div>
    <div class="content">
      <van-pull-refresh v-model="peddingOption.refreshing" @refresh="onRefresh">
        <van-list
          v-model="peddingOption.loading"
          :finished="peddingOption.finished"
          finished-text="没有更多了"
          @load="onLoad"
          :error.sync="peddingOption.error"
          error-text="请求失败，点击重新加载"
        >
          <!-- :class="['box', { box1: item.zt3 == '4' }]" 判断数据是不是已失效背景色替换 -->
          <!-- peddingOption.list接口对接后替换掉循环里面的data -->
          <div
            :class="['box', { box1: item.pmOutFlag == '0' }]"
            v-for="(item, index) in peddingOption.list"
            :key="index"
            @click="clickMessage(item)"
          >
            <!-- :class="item.zt3 == '4' ? 'boxImg' : ''" 判断数据失效加上消息已失效图片 -->
            <div :class="item.pmOutFlag == '0' ? 'boxImg' : ''"></div>
            <div
              :class="
                item.pmcIfRead == '0'
                  ? 'read'
                  : item.pmcIfRead == '1'
                  ? 'read1'
                  : ''
              "
            ></div>
            <div class="boxT">
              <div>{{ item.pmTitle }}</div>
              <p>企业名称：{{ item.companyName }}</p>
            </div>
            <div class="boxC">
              <div>
                {{ item.pmMessage }}
              </div>
            </div>
            <div class="boxLxr">
              <p>发 送 人 ：{{ item.pmStudyOutPeople }}</p>
            </div>
            <div class="boxDb">
              <div>发送时间: {{ item.pmcUpdateTime }}</div>
              <p v-if="item.pmcIfBack == '0' && item.pmcNeedBack != '0'">
                <span :class="item.pmcIfBack == '0' ? 'img' : ''"></span>
                未反馈
              </p>
              <p v-if="item.pmcIfBack == '1' && item.pmcNeedBack != '0'">
                <span :class="item.pmcIfBack == '1' ? 'img1' : ''"></span>
                已反馈
              </p>
              <p v-if="item.pmcNeedBack == '0'">
                <span :class="item.pmcNeedBack == '0' ? 'img2' : ''"></span>
                无需反馈
              </p>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
      <iframe
        :src="src"
        frameborder="0"
        style="width: 300px; height: 300px"
      ></iframe>
    </div>
  </div>
</template>

<script>
import { getMessageListToWeChat } from "../api/home"; // 接口暂用的其他列表接口进home.js 加上列表替换就行
import { Toast } from "vant";
export default {
  data() {
    return {
      userInfo: {},
      src: "",
      optionType: "peddingOption",
      peddingOption: {
        list: [],
        loading: false,
        finished: false,
        refreshing: false,
        page: 1,
        // rcRlzt: 0,
        error: false,
        limit: 10,
        total: 0,
      },
    };
  },
  mounted() {
    // let userInfo = this.$store.state.common.userInfo;
    // if (!userInfo) {
    //   localStorage.removeItem("vuex");
    //   this.$router.push({ path: "/mine" });
    // } else {
    //   this.userInfo = userInfo;
    // this.getcount();
    // }
    if (
      !localStorage.getItem("openid") ||
      localStorage.getItem("openid") == "undefined"
    ) {
      let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx47b46760287ffd31&redirect_uri=https://qtqyzx.qiantang.gov.cn&response_type=code&scope=snsapi_userinfo#wechat_redirect`;
      window.location.href = url;
    }
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      this[this.optionType].finished = false;
      this[this.optionType].loading = true;
      this[this.optionType].page = 1;
      this[this.optionType].list = [];
      this.getcount();
    },

    //上拉加载
    onLoad() {
      // this[this.optionType].finished = true;
      this.getcount();
    },

    backLevel(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },
    backHome(e) {
      e.preventDefault();
      wx.closeWindow();
      return false;
    },

    //详情跳转
    clickMessage(data) {
      if (data.pmOutFlag == "0") {
        Toast.fail("数据已失效");
      } else {
        if (data.mark == "1") {
          this.$router.push({
            path: "/myMessage-view",
            query: { pmcUuid: data.pmcUuid, mark: "1" },
          });
        } else {
          this.$router.push({
            path: "/myMessage-view",
            query: { pmcUuid: data.pmcUuid },
          });
        }
      }
    },

    getcount() {
      let target = this[this.optionType];
      //接口传递参数
      let data = {
        page: target.page,
        limit: target.limit,
        openId: localStorage.getItem("openid"),
      };
      //列表接口
      getMessageListToWeChat(data)
        .then((res) => {
          if (res.success) {
            target.page++;
            if (target.refreshing) {
              target.list = [];
              target.refreshing = false;
            }
            let count = res.totalCount || 0;
            let list = res.data;

            target.loading = false;
            target.list = target.list.concat(list);
            if (target.list.length >= count) {
              // console.log("if");
              target.finished = true;
            }
          } else {
            target.page = 1;
            target.list = [];
            target.loading = false;
            target.finished = true;
            target.refreshing = false;
            if (res.actionMessages && res.actionMessages[0]) {
              const msg = res.actionMessages[0];
              Toast.fail(msg);
            }
          }
        })
        .catch((err) => {
          target.error = true;
          target.finished = true;
          // console.log(err, "消息错误");
          Toast.fail("数据获取失败，请刷新重试");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  width: 100%;
  height: rem(293);
  background: url("../assets/images/tb.png");
  background-size: 100% 100%;
  padding-top: rem(109);
  padding-left: rem(53);
  div {
    width: rem(243);
    height: rem(59);
    font-weight: Heavy;
    font-size: rem(48);
    color: #ffffff;
  }
  p {
    // width: rem(144);
    // height: rem(30);
    font-weight: Medium;
    font-size: rem(36);
    color: #ffffff;
  }
}
.content {
  width: 100%;
  padding: rem(33) rem(32) 0 rem(32);
  z-index: 1000;
  .box1 {
    background: #fffafa;
  }
  .box {
    width: rem(686);
    height: rem(347);
    padding: 0 rem(22) 0 rem(22);
    box-shadow: 0 rem(2) rem(16) 0 rgba(0, 0, 0, 0.09);
    border-radius: rem(3);
    overflow: hidden;
    position: relative;
    margin-bottom: rem(24);
    .boxImg {
      width: rem(292);
      height: rem(193);
      position: absolute;
      margin: auto;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: url("../assets/images/yxs.png") no-repeat;
      background-size: 100% 100%;
    }
    .read {
      position: absolute;
      top: rem(0);
      right: rem(0);
      background: #191f25;
      width: rem(117);
      height: rem(40);
      background: url("../assets/images/wd.png");
      background-size: 100% 100%;
    }
    .read1 {
      position: absolute;
      top: rem(0);
      right: rem(0);
      background: #191f25;
      width: rem(117);
      height: rem(40);
      background: url("../assets/images/yd.png");
      background-size: 100% 100%;
    }
    .boxT {
      div {
        width: 100%;
        height: rem(42);
        font-weight: Regular;
        font-size: rem(26);
        line-height: rem(42);
        color: #191f25;
        margin-top: rem(17);
        margin-bottom: rem(13);
      }
      p {
        width: 100%;
        height: rem(24);
        font-weight: Regular;
        line-height: rem(24);
        font-size: rem(24);
        color: #999999;
        margin-bottom: rem(16);
      }
    }
    .boxC {
      width: 100%;
      min-height: rem(114);
      background: #eeeeee;
      border-radius: rem(4);
      padding: rem(10) rem(17) rem(14) rem(18);
      div {
        font-weight: Regular;
        font-size: rem(24);
        color: #999999;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
    .boxLxr {
      width: 100%;
      height: rem(55);
      line-height: rem(55);
      border-bottom: rem(1) solid rgba(0, 0, 0, 0.09);
      font-weight: Regular;
      font-size: rem(24);
      color: #999999;
    }
    .boxDb {
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      padding-top: rem(15);
      div {
        width: rem(313);
        height: rem(36);
        line-height: rem(36);
        font-weight: Normal;
        font-size: rem(20);
        color: #3296fa;
        background: url("../assets/images/bj.png");
        background-size: 100% 100%;
        border: rem(1) dashed #999999;
      }
      p {
        font-weight: Regular;
        font-size: rem(24);
        line-height: rem(24);
        padding-top: rem(8);
        color: #191f25;
        display: flex;
        .img {
          width: rem(24);
          height: rem(24);
          display: block;
          background: url("../assets/images/x.png");
          background-size: 100% 100%;
        }
        .img1 {
          width: rem(24);
          height: rem(24);
          display: block;
          background: url("../assets/images/y.png");
          background-size: 100% 100%;
        }
        .img2 {
          width: rem(24);
          height: rem(24);
          display: block;
          background: url("../assets/images/n.png");
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>
